body,html {
  margin: 0;
  font-family: "Helvetica Neue",Helvetica,Arial,sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height:-webkit-fill-available;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  width: 100vw;
  min-height: var(--vh);
  min-width: 1400px;
  background: #F3F7FA;
}

.app-layout {
  height: 100%;
}
#root {
  background: #F3F7FA;
  min-height: var(--vh);
}

.no-scroll {
  overflow: hidden;
}

@supports (bottom: env(safe-area-inset-bottom)){
  body,
  .safe-area{
      padding-bottom: constant(safe-area-inset-bottom);
      padding-bottom: env(safe-area-inset-bottom);
  }
}
